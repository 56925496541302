<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <div>
     

      <b-row>



          <div class="scans-canvas"  id="element-to-convert">
            <scan-canvas v-if="scan" :scan="scan" />
       
          </div>
       
       
      </b-row>
 
      <br /> 

<b-button @click="generateReport()"
  
  variant="dark"
>
  PDF
</b-button>

  
    </div>
  </b-overlay>
</template>

<script>

import {BModal, BButton,BOverlay, VBModal, BRow, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Woocommerce} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ScanPopup from '../../components/ScanPopup.vue'
import PrescriptionPopup from '../../components/PrescriptionPopup.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import emitter from 'tiny-emitter/instance'
import ScanCanvas from './ScanCanvas.vue'
import ScanViewer from './ScanViewer.vue'
import html2pdf from "html2pdf.js";

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BOverlay,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BRow,
    ScanPopup,
    PrescriptionPopup,
    ScanCanvas,
    ScanViewer
  },
  data() {
    return {
      scan:null,
      selectedScan:localStorage.senseye_b2b_selected_scan,
      loading:true,
      measurements:null
    }
  },
  
  methods:{
   
    generateReport () {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 0,
        filename: "generated-pdf.pdf",
      });
    },
    async getScan() {
      let scan=await APIRequest.detail('scans',this.$route.params.id)
      this.scan={}
      this.scan=scan

      if(scan.measurements)
      {
        let measurements=scan.measurements.replace('."',',"')
        measurements=measurements.replace('."',',"')
        measurements=measurements.replace('."',',"')
        measurements=measurements.replace('."',',"')
        
    
        this.measurements=JSON.parse(measurements)
      }
      


      //console.log(measurements)
      //import data from "/Users/Documents/workspace/test.json"
    },
    selectScan(id) {
      this.selectedScan=this.scan.id
      localStorage.senseye_b2b_selected_scan=this.scan.id
   
    },
    
    
  },
  watch:{
    
  },
  async mounted()
  {
    this.loading=true
    
    await this.getScan()
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
.scans-canvas
{
  display:flex;
  width:100%;
  flex-direction: row;
  height:100%;
}

.overlay-scan {
  width:100%;
  height:100vh;
  z-index:999999;
}
</style>
